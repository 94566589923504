<script>
  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import { ApiError } from '@/api'
  import AccountAddOnContent from '@/components/account/AccountAddOnContent'

  export default {
    components: {
      AccountAddOnContent,
    },
    props: {
      subscriptionId: {
        type: Number,
        required: true,
      },
      type: {
        type: String,
        required: true,
        validator: (value) => ['magazine', 'eco-upgrade'].includes(value),
      },
    },
    data() {
      return {
        error: null,
      }
    },
    computed: {
      screen,
      ...get('subscribe', ['addOnSubmitting', 'addOnSuccess']),
      backLink() {
        if (this.$route.name === 'SubscribeBonusConfirmation') {
          return { name: 'SubscribeBonuses', params: { ...this.$route.params } }
        } else if (this.$route.name === 'CustomizeBonusConfirmation') {
          return { name: 'CustomizeBonuses', params: { ...this.$route.params } }
        } else {
          return { name: 'AccountProfileSubscriptions' }
        }
      },
    },
    watch: {
      addOnSuccess: {
        async handler() {
          if (!this.addOnSuccess) {
            return
          }
          await this.fetchProfile()
          setTimeout(() => {
            if (this.$route.name === 'SubscribeBonusConfirmation') {
              this.$navigate({ name: 'SubscribeBonuses', params: { ...this.$route.params } })
            } else if (this.$route.name === 'CustomizeBonusConfirmation') {
              this.$navigate({ name: 'CustomizeBonuses', params: { ...this.$route.params } })
            } else if (this.$route.name === 'AccountAddOn') {
              this.$navigate({ name: 'AccountProfileSubscriptions' })
            }
            this.$store.set('subscribe/addOnSuccess', false)
          }, 1000)
        },
      },
    },
    methods: {
      ...call('account', ['fetchProfile']),
      ...call('subscribe', ['addMagazine', 'addEcoUpgrade']),
      async submitAddOn() {
        try {
          if (this.type === 'magazine') {
            await this.addMagazine({ subscriptionId: this.subscriptionId })
          } else if (this.type === 'eco-upgrade') {
            await this.addEcoUpgrade({ subscriptionId: this.subscriptionId })
          }
        } catch (error) {
          if (error instanceof ApiError) {
            this.error = error.data.message
          } else {
            this.error =
              'Something went wrong. Please try again and contact us at help@alltrue.com if the problem persists.'
          }
        }
      },
      scrollToBottom() {
        this.$refs.accountAddOnContent.scrollToBottom()
      },
    },
  }
</script>

<template>
  <div>
    <BaseLinkStyled
      v-if="!screen.md"
      color="black"
      font-size="xs"
      icon-position="left"
      :icon-size="5"
      :to="backLink"
      class="mt-4 mb-8 ml-2"
    >
      <template v-slot:icon>
        <IconArrowLeft />
      </template>
    </BaseLinkStyled>

    <!-- Error handling -->
    <BasePanelWarning v-if="error" class="md:mt-12 mb-2">
      {{ error }}
    </BasePanelWarning>

    <AccountAddOnContent ref="accountAddOnContent" :type="type" />

    <Portal v-if="!screen.md" to="fixedBottom">
      <BaseTransitionFixedBottom>
        <div>
          <BaseButton
            height="short"
            :class="`${screen.md ? 'w-64' : 'w-full'}`"
            @click="submitAddOn"
          >
            {{ type === 'magazine' ? 'Upgrade for $7.95/season' : 'Add Eco-Upgrade' }}
          </BaseButton>
          <p
            :class="`text-2xs text-center pt-2 leading-tight ${
              screen.md ? '' : 'bg-black text-white pb-2 px-4'
            }`"
            >Membership automatically renews, but you can cancel at any time in your account
            settings.
            <BaseLinkStyled :color="screen.md ? 'black' : 'white'" @click="scrollToBottom"
              >Learn more&#42;</BaseLinkStyled
            >
          </p>
        </div>
      </BaseTransitionFixedBottom>
    </Portal>

    <div
      v-else
      class="z-40 -mx-10 py-3 px-22 sticky bottom-0 border-t-1 border-gray-active shadow-md flex flex-col items-center bg-dawn"
    >
      <BaseButton height="short" class="w-64" @click="submitAddOn">
        {{ type === 'magazine' ? 'Upgrade for $7.95/season' : 'Add Eco-Upgrade' }}
      </BaseButton>
      <p class="text-2xs text-center pt-2 leading-tight"
        >Membership automatically renews, but you can cancel at any time in your account settings.
        <BaseLinkStyled @click="scrollToBottom">Learn more&#42;</BaseLinkStyled></p
      >
    </div>
    <!-- Loading spinner -->
    <BaseSpinner
      v-if="addOnSubmitting || addOnSuccess"
      :success="addOnSuccess"
      overlay="fixed"
      overlay-color="white"
      class="z-90"
    />
  </div>
</template>
